<template>
  <form-dialog
    v-if="is_admin"
    v-model="dialog"
    :is-saving="is_sending"
    :title="$t('PostCovers')"
    v-on:submit="save"
    max-width="800px"
    show-cancel
  >
    <template v-slot:content>
      <div class="select-desc">
        <div class="mb-3 text-center">{{ $t("PostCoversDesc") }}</div>
        <div class="mt-2 mb-5 text-center">
          <v-btn
            @click="openGallery"
            class="text-none"
            outlined
            color="primary"
          >
            <v-icon left>$vuetify.icons.imageOutline</v-icon>
            {{ $t("SelectFromAlbum") }}</v-btn
          >
        </div>
      </div>
      <v-divider></v-divider>
      <div
        v-if="!selected_images || selected_images.length == 0"
        class="mt-6 mb-4 text-center"
      >
        {{ $t("NoSelectedImages") }}
      </div>

      <v-row
        v-if="selected_images && selected_images.length > 0"
        class="images-container mt-1 mb-5"
      >
        <draggable
          v-model="selected_images"
          @start="drag = true"
          @end="drag = false"
          tag="ul"
        >
          <li v-for="(item, i) in selected_images" :key="item.id">
            {{ (i += 1) }}

            <v-card height="150" class="img-card">
              <v-img
                :src="$imagehelpers.mediaThumb(item.image)"
                height="150"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-btn
                color="secondary"
                fab
                x-small
                dark
                top
                right
                absolute
                :title="$t('RemoveCover')"
                class="mt-6 mr-n2 edit-btn"
                @click="removeCover(item.id)"
              >
                <v-icon small>$vuetify.icons.close</v-icon>
              </v-btn>
            </v-card>
          </li>
        </draggable>
      </v-row>

      <error-box :error="error"></error-box>

      <media-picker
        v-on:done="selectedMedia"
        ref="mediaPicker"
        :diary="diary.name"
        :max="MAX_IMAGES"
      ></media-picker>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
      "PostCovers": "Edit cover",
      "PostCoversDesc": "You have the option to select up to 4 images.",
      "PostUpdated": "Post updated!",
      "SelectFromAlbum": "Select from album",
      "NoSelectedImages": "No images will be displayed on post!",
      "RemoveCover": "Remove cover"
    },
    "sv": {
      "PostCovers": "Ändra omslagsbild",
      "PostCoversDesc": "Du kan välja upp till 4 bilder.",
      "PostUpdated": "Inlägget uppdaterades!",
      "SelectFromAlbum": "Välj från album",
      "NoSelectedImages": "Inga bilder kommer visas för denna post!",
      "RemoveCover": "Ta bort bild"
    }
    }
</i18n>

<script>
import { mapActions, mapState } from "vuex";
import MediaPicker from "./MediaPickerDialog.vue";
import draggable from "vuedraggable";

export default {
  name: "post_covers_dialog",
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  data: () => ({
    dialog: false,
    is_sending: false,
    error: null,

    MAX_IMAGES: 4,

    blog_post: null,

    selected_images: [],
  }),
  created: function () {},
  components: {
    "media-picker": MediaPicker,
    draggable,
  },
  methods: {
    ...mapActions({
      updateCover: "diary/news/updateCover",
    }),

    open(post) {
      this.error = null;
      this.is_sending = false;
      this.dialog = true;
      this.blog_post = { ...post };

      if (this.blog_post.cover && this.blog_post.cover.length > 0) {
        for (let i = 0; i < this.blog_post.cover.length; i++) {
          this.selected_images.push(this.blog_post.cover[i]);
        }
      }
    },
    removeCover(id) {
      for (var i = 0; i < this.selected_images.length; i++) {
        if (this.selected_images[i].id == id) {
          this.selected_images.splice(i, 1);
          return;
        }
      }
    },
    save() {
      var self = this;
      self.error = null;
      self.is_sending = true;

      self
        .updateCover({
          id: self.blog_post.id,
          images: self.selected_images,
        })
        .then(function () {
          self.is_sending = false;
          self.dialog = false;
          self.blog_post.covers = self.selected_images;
          self.$emit("updated", self.blog_post);
          self.$successNoty(self.$t("PostUpdated"));
        })
        .catch(function (error) {
          self.error = error;
          self.is_sending = false;
        });
    },
    openGallery() {
      this.$refs.mediaPicker.open();
    },
    selectedMedia(items) {
      var self = this;

      if (items.length > 0) {
        for (var i = 0; i < items.length; i++) {
          var obj = items[i];

          var old = self.selected_images.find((p) => p.id == obj.id);

          if (!old && self.selected_images.length < self.MAX_IMAGES)
            self.selected_images.push(obj);
        }
      }
    },
  },
};
</script>

<style scoped>
.images-container ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.images-container ul li {
  float: left;
  width: 25%;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 15px;
}
.images-container ul li .img-card {
  cursor: move;
}

@media screen and (max-width: 768px) {
  .images-container ul li {
    width: 50%;
  }
}
</style>